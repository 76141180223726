<template>
  <div class="bigWaybillManage">
    <div class="facilityBox">
      <input
        type="file"
        style="display: none"
        @change="handleUploadWaybill"
        ref="waybill"
      />
      <el-form
        class="searchForm"
        :model="searchForm"
        ref="searchForm"
        label-width="120px"
      >
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item
            label="运单号："
            prop="waybillID"
          >
            <el-input
              v-model="searchForm.waybillID"
              placeholder="请输入运单号"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="客户单号："
            prop="carriageBillID"
          >
            <el-input
              v-model="searchForm.carriageBillID"
              placeholder="请输入客户单号"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="车牌号："
            prop="carNumber"
          >
            <el-input
              v-model="searchForm.carNumber"
              placeholder="请输入车牌号"
            >
            </el-input>
          </el-form-item>

          <el-form-item label-width="10px">
            <div style="white-space: nowrap">
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                @click="search"
                icon="el-icon-search"
              >搜索</el-button>
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                icon="el-icon-delete"
                @click="resetForm"
              >清空</el-button>
              <el-button
                type="primary"
                size="medium"
                @click="toGetTemplate"
                icon="el-icon-download"
                v-if="roleType != 'platform'"
              >
                下载模板
              </el-button>
              <el-button
                type="primary"
                size="medium"
                @click="$refs.waybill.click()"
                icon="el-icon-upload2"
                v-if="roleType != 'platform'"
              >
                导入运单表格
              </el-button>
              <el-button
                type="danger"
                size="medium"
                @click="delWaybill"
                icon="el-icon-delete"
                v-if="roleType != 'platform'"
                :disabled="!multipleSelection.length"
              >
                勾选删除运单
              </el-button>
              <el-button
                size="medium"
                @click="showMoreSearch = !showMoreSearch"
                :icon="showMoreSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              >
                展开
              </el-button>
            </div>
          </el-form-item>
        </div>
        <div
          style="display: flex; flex-wrap: wrap"
          v-if="showMoreSearch"
        >
          <el-form-item
            label="司机姓名："
            prop="driverName"
          >
            <el-input
              v-model="searchForm.driverName"
              placeholder="请输入司机姓名"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="司机手机号："
            prop="driverPhone"
          >
            <el-input
              v-model="searchForm.driverPhone"
              placeholder="请输入司机手机"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="司机身份证号："
            prop="driverIDCard"
          >
            <el-input
              v-model="searchForm.driverIDCard"
              placeholder="请输入司机身份证号"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="服务商名称："
            prop="supplerName"
            v-if="roleType == 'platform'"
          >
            <el-input
              v-model="searchForm.supplerName"
              placeholder="服务商名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="主体公司："
            prop="goodsOwnerName"
          >
            <el-input
              v-model="searchForm.goodsOwnerName"
              placeholder="请输入货主单位(主体公司)"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="子体公司："
            prop="goodsOwnerNameChild"
          >
            <el-input
              v-model="searchForm.goodsOwnerNameChild"
              placeholder="请输入货主单位(子体公司)"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="起始地："
            prop="originAddress"
          >
            <el-input
              v-model="searchForm.originAddress"
              placeholder="请输入起始地"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="到达地："
            prop="arriveAddress"
          >
            <el-input
              v-model="searchForm.arriveAddress"
              placeholder="请输入到达地"
            >
            </el-input>
          </el-form-item>

        </div>

      </el-form>

      <ux-grid
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        style="width: 100%"
        height="600"
        @select="handleSelectionChange"
        @select-all="handleSelectionChange"
        show-overflow
        :border="false"
        :row-style="{height:'55px'}"
        ref="uxGridRef"
      >
        <ux-table-column
          type="checkbox"
          align="center"
          width="50"
          fixed="left"
          v-if="roleType != 'platform'"
        ></ux-table-column>
        <ux-table-column
          type="index"
          align="center"
          title="序号"
          width="50"
          fixed="left"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="SupplerName"
          title="服务商名称"
          width="200"
          fixed="left"
          show-overflow
          v-if="roleType == 'platform'"
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="WaybillID"
          title="运单号"
          width="200"
          show-overflow
        ></ux-table-column>

        <ux-table-column
          align="center"
          field="CarriageBillID"
          title="客户单号"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="GoodsOwnerName"
          title="货主单位(主体公司)"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="GoodsOwnerNameChild"
          title="货主单位(子体公司)"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="$refs.enterpriseDetail.watchDetail(scope.row)"
            >{{  scope.row.GoodsOwnerNameChild  }}</el-link>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="DriverName"
          title="司机姓名"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="DriverPhone"
          title="司机手机号"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="DriverIDCard"
          title="司机身份证号"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkUser(scope.row, 'DriverIDCard')"
            >
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="PayeeName"
          title="收款人姓名"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="PayeePhone"
          title="收款人手机号"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="PayeeIDCard"
          title="收款人身份证号"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkUser(scope.row, 'PayeeIDCard')"
            >
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="LeaderName"
          title="车队长姓名"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="LeaderPhone"
          title="车队长手机号"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="LeaderIDCard"
          title="车队长身份证号"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkUser(scope.row, 'LeaderIDCard')"
            >
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="CarNumber"
          title="车牌号"
          width="150"
          show-overflow
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkInfo(scope.row)"
            >
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="LoadNetWeight"
          title="装货净重(吨)"
          width="150"
          show-overflow
        >
        </ux-table-column>
        <ux-table-column
          align="center"
          field="GoodsName"
          title="货品名称"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="GoodsUnit"
          title="货品单位"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="LoadingDatetime"
          title="装货时间"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="SignDatetime"
          title="签收时间"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="OriginAddress"
          title="起始地"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="ArriveAddress"
          title="到达地"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="DriverAllotCount"
          title="司机装货数量"
          width="150"
          show-overflow
        >
        </ux-table-column>
        <ux-table-column
          align="center"
          field="DriverCarryPrice"
          title="司机运输单价(人民币)"
          width="150"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice  }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="CarriageTotalPrice"
          title="运费"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="ExpensesOfTaxation"
          title="服务费/税费"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="TotalAmount"
          title="运单总金额"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="EntrustedCarriageTotalPrice"
          title="委托代开运费"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedCarriageTotalPrice }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="EntrustedServiceTotalPrice"
          title="委托代开服务费/税费"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedServiceTotalPrice }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="EntrustedTotalAmount"
          title="委托代开运单总金额"
          width="200"
          show-overflow
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTotalAmount | formatMoney }}
            </div>
          </template>
        </ux-table-column>
        <ux-table-column
          align="center"
          field="UnloadNetWeight"
          title="卸货净重(吨)"
          width="150"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="OriginalWayBillID"
          title="溯源运单号"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          field="PayeeRemark"
          title="收款人备注"
          width="200"
          show-overflow
        ></ux-table-column>
        <ux-table-column
          align="center"
          fixed="right"
          title="操作"
          width="300"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="toEditWaybill(scope.row)"
              v-if="parentRoleId != 1"
            >编辑</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="watchEvidence(scope.row)"
            >查看凭证</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="openPdmModel(scope.row)"
            >打印运单</el-button>
          </template></ux-table-column>
      </ux-grid>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="handleCurrentChange"
        @size-change="sizeChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        :page-sizes="[10, 50, 100, 500, 1000]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <!-- 查看凭证 -->
    <el-dialog
      width="800px"
      title="凭证详情"
      center
      :visible.sync="evidenceDialog"
      append-to-body
      v-if="evidenceDialog"
      :close-on-click-modal="false"
      class="evidence-dialog"
      v-loading="evidenceDialogLoading"
    >
      <div
        class="item"
        v-for="(item, idx) in urlsObj"
        :key="idx"
      >
        <el-descriptions
          title=""
          direction="vertical"
          :column="2"
          size="small"
          border
        >
          <el-descriptions-item
            :label="item.name"
            :span="roleType != 'platform' ? 1 : 2"
            :labelStyle="{ 'text-align': 'center' }"
            labelClassName="my-label"
          >
            <template v-if="forEachList(urls[item.key], false).length">
              <div style="display: flex; align-items: center; flex-wrap: wrap">
                <el-image
                  style="width: 49%; height: 200px"
                  :src="urlIt"
                  fit="contain"
                  v-for="(urlIt, urlIdx) in forEachList(
                  urls[item.key],
                  false
                )"
                  :preview-src-list="urls[item.key]"
                  :key="urlIdx + urlIt"
                  class="imgItem"
                ></el-image>
              </div>
            </template>
            <template v-if="forEachList(urls[item.key], true).length">
              <el-link
                type="primary"
                @click="downLoadFile(urlIt)"
                v-for="(urlIt, urlIdx) in forEachList(urls[item.key], true)"
                :key="urlIdx + urlIt"
                class="textItem"
              >{{
                  urlIt }}</el-link>
            </template>
          </el-descriptions-item>

          <el-descriptions-item
            label="操作"
            :labelStyle="{ 'text-align': 'center' }"
            labelClassName="my-label-action"
            v-if="roleType != 'platform'"
          >
            <el-upload
              :show-file-list="false"
              :auto-upload="false"
              :on-change="(file,list) => uploadChange(file,list, item.keyName)"
              action="#"
              :limit="1"
              :ref="`uploadRef-${item.keyName}`"
            >
              <el-button
                type="primary"
                plain
                icon="el-icon-upload2"
                style="width: 100%;"
              >新增{{ item.name }}</el-button>
            </el-upload>

          </el-descriptions-item>

        </el-descriptions>
      </div>
    </el-dialog>

    <!-- 司机信息组件 -->
    <el-dialog
      top="4vh"
      width="1300px"
      :visible.sync="flag.showDriver"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">司机信息</span>
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>

    <!-- 车辆信息组件 -->
    <el-dialog
      width="1300px"
      top="4vh"
      :visible.sync="flag.carType"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
    </el-dialog>

    <el-dialog
      width="800px"
      :visible.sync="flag.showMap"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :list="mapList"
      >
      </TXmap>
    </el-dialog>

    <!-- 查看详情 -->
    <enterpriseDetail ref="enterpriseDetail"></enterpriseDetail>
  </div>
</template>

<script>
import {
  WaybillList,
  ImportWaybill,
  WaybillDel,
  DriverDetail,
  CarDetail,
  getMapPath,
  getTemplate,
  WaybillDetail,
  EditWaybill,
  getPrintWaybill
} from "@/api/bigCustomerManage/index";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import { _getParentRoleId, _getEnterpriseName } from "@/utils/storage";
import { upLoadFile2Url } from '@/api/common/common.js'
import enterpriseDetail from '@/views/supplier/bigCustomerManage/bigEnterpriseManage/enterpriseDetail.vue'

export default {
  props: {
    roleType: { // 角色类型
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      parentRoleId: null,
      showMoreSearch: false,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索表单
      searchForm: {
        waybillID: '',
        carriageBillID: '',
        driverIDCard: '',
        supplerName: '',
        driverName: '',
        driverPhone: '',
        carNumber: '',
        goodsOwnerName: '',
        goodsOwnerNameChild: ''
      },
      tableData: [], //表格数据
      loading: false,
      evidenceDialog: false,
      evidenceDialogLoading: false,
      evidenceData: null,
      urlsObj: [
        {
          name: "银行回单",
          key: "bankReceipt",
          keyName: "BankReceipt",
        },
        {
          name: "合同",
          key: "contractUrl",
          keyName: "ContractUrl",
        },
        {
          name: "运输凭证",
          key: "evidenceUrl",
          keyName: "EvidenceUrl",
        },
        {
          name: "代收协议",
          key: "collectionAgreementUrl",
          keyName: "CollectionAgreementUrl",
        }
      ],
      urls: {
        bankReceipt: [], // 银行回单
        contractUrl: [], // 合同
        evidenceUrl: [], // 运输凭证
        collectionAgreementUrl: [], // 代收协议
      },
      multipleSelection: [],
      // 司机信息
      driverInfo: {},
      // 车辆信息
      vehicleInfo: {},
      // 控制弹窗
      flag: {
        // 轨迹回放弹窗
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
      },
      pdfModelTitle: '',
      printWaybillObj: null
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async watchEvidence(item, isUpload) {
      // isUpload： 更新凭证

      try {
        this.evidenceDialogLoading = true

        let next = (data) => {
          if (data.BankReceipt) {
            this.urls.bankReceipt = data.BankReceipt.split(',')
          }
          if (data.ContractUrl) {
            this.urls.contractUrl = data.ContractUrl.split(',')
          }
          if (data.EvidenceUrl) {
            this.urls.evidenceUrl = data.EvidenceUrl.split(',')
          }
          if (data.CollectionAgreementUrl) {
            this.urls.collectionAgreementUrl = data.CollectionAgreementUrl.split(',')
          }
          this.evidenceDialog = true
        }

        if (!isUpload) {
          let { data = {} } = await WaybillDetail({
            userId: item.UserID,
            waybillID: item.WaybillID,
          })
          this.evidenceData = data
          next(data)
        } else {
          next(item)
        }

      } finally {
        this.evidenceDialogLoading = false
      }


    },
    isNotImage(filename) {
      return !/\.(jpg|jpeg|png|gif)$/i.test(filename); // 返回结果为true或false，根据文件名是否符合图像格式后缀进行判断
    },
    downLoadFile(url) {
      window.open(url)
    },
    forEachList(list, type) {
      let imgList = list.filter((item) => this.isNotImage(item) == type);
      return imgList.length ? imgList : [];
    },
    // 删除运单
    delWaybill() {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.toDelFn(this.multipleSelection)
        })
        .catch(() => { });
    },

    toDelFn(arr) {
      if (!arr.length) return;

      this.loading = true
      let len = arr.length; // 请求总数
      let sendCount = 0; // 已发送的请求数量
      let errorCount = 0; // 错误请求
      const _this = this;

      return new Promise((resolve, reject) => {
        next();

        function next() {
          let current = sendCount++; // 当前发送的请求数量，后加一 保存当前请求url的位置
          if (arr[current]) {
            WaybillDel({
              userID: arr[current].UserID,
              waybillID: arr[current].WaybillID,
            }).then(() => {
            }).catch(() => {
              errorCount = errorCount + 1;
            }).finally(() => {

              if (current < len - 1) { // 如果请求没有发送完，继续发送请求
                next();
              } else if (current == len - 1) {
                if (errorCount) {
                  _this.$message.warning(`存在${errorCount}条，删除异常`)
                } else {
                  _this.$message.success("删除成功");
                }

                _this.search();
                _this.multipleSelection = []
                _this.$refs.uxGridRef.clearSelection()
                _this.loading = false

                resolve();
                return;
              }
            })
          }
        }
      })
    },

    //上传运单
    handleUploadWaybill(e) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let formData = new FormData()
      formData.append('file', e.srcElement.files[0] || e.target.files[0])
      ImportWaybill(formData)
        .then((res) => {

          if (res.data) {
            this.$message.warning("导入成功，正在下载移除数据表格！")
            this.downFileFn(res.data)
          } else {
            this.$message.success("导入成功")

          }
          this.getAllBillList()
        })
        .finally(() => {
          this.$refs.waybill.value = null
          loading.close()
        })
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.loading = true;
      this.getAllBillList();
    },
    //清空
    resetForm() {
      this.loading = true;
      this.searchForm = {
        waybillID: '',
        carriageBillID: '',
        driverIDCard: '',
        supplerName: '',
        driverName: '',
        driverPhone: '',
        carNumber: '',
        goodsOwnerName: '',
        goodsOwnerNameChild: ''
      };
      this.pagination.page = 1;
      this.getAllBillList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getAllBillList();
    },
    //分页数量改变
    sizeChange(e) {
      this.loading = true;
      this.pagination.pagesize = e;
      this.getAllBillList();
    },
    //获取所有运单列表
    getAllBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        isPlat: this.roleType == 'platform' ? true : false,
        ...this.searchForm
      };

      this.loading = true;
      WaybillList(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 司机信息 - 弹框
    checkUser(item, idCard) {
      this.loading = true;
      DriverDetail({ userID: item.UserID, driverIDCard: item[idCard] })
        .then(({ data = {} }) => {
          this.driverInfo = data;
          this.flag.showDriver = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading = true;
      let params = {
        vehicleCarNumber: item.CarNumber,
        userId: item.UserID
      };
      CarDetail(params)
        .then(({ data = {} }) => {
          this.vehicleInfo = {
            ...data,
            CarNumberColorName: data?.CarNumberColor || '',
            VehiclePowerTypeName: data?.VehiclePowerType || '',
          };
          this.flag.carType = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //打开地图
    openMap(item, openDialog = true) {
      this.loading = true;
      getMapPath({ wayBillId: item.WaybillID })
        .then(({ data = {} }) => {
          this.mapList = data;
          if (openDialog) this.flag.showMap = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 编辑大客户运单
    toEditWaybill(item) {
      this.$router.push({
        path: "/bigCustomerManage/bigWaybillManage/editWaybill",
        query: {
          pagination: { ...this.pagination },
          searchForm: { ...this.searchForm },
          userId: item.UserID,
          waybillID: item.WaybillID,
        },
      });
    },

    // 下载模板
    async toGetTemplate() {
      try {
        this.loading = true
        let { data = '' } = await getTemplate({
          id: 1
        })
        this.downFileFn(data)
      } finally {
        this.loading = false
      }
    },

    downFileFn(data) {
      if (data) {
        const el = document.createElement("a");
        el.style.display = "none";
        el.setAttribute("target", "_blank");
        el.setAttribute("download", "");
        el.href = data;
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      } else {
        this.$message.error('模板异常，请联系管理员')
      }
    },

    // 上传文件
    async uploadChange(file, list, key) {
      let formData = new FormData();
      formData.append("file", file.raw);

      try {
        this.evidenceDialogLoading = true
        let { data = '' } = await upLoadFile2Url(formData)
        this.evidenceData[key] = this.evidenceData[key] ? this.evidenceData[key] + ',' + data : data;
        await EditWaybill(this.evidenceData)
        this.$message({
          message: '添加成功',
          type: 'success'
        });

        this.watchEvidence(this.evidenceData, 'upload')
      } finally {
        this.evidenceDialogLoading = false
        if (this.$refs[`uploadRef-${key}`] && this.$refs[`uploadRef-${key}`][0]) this.$refs[`uploadRef-${key}`][0].clearFiles()
      }
    },

    getFileType(fileName) {
      let lastDotIndex = fileName.lastIndexOf('.');
      let fileExtension = fileName.slice(lastDotIndex);
      return fileExtension;
    },

    async getPrintWaybillObj(record, WaybillID, SupplierName) {
      let res = await getPrintWaybill({
        userID: record.UserID,
        waybillId: WaybillID
      });
      if (res) {
        let saveObj = res.data

        let CarUrls = [], DriverUrls = [];
        CarUrls.push(saveObj.Vehicleinfo?.VehicleLicenseFrontPageURL, saveObj.Vehicleinfo?.VehicleLicenseSubPageOnURL, saveObj.Vehicleinfo?.VehicleRTPUTL)
        DriverUrls.push(saveObj.Driverinfo?.DriverCardOnURL, saveObj.Driverinfo?.DriverCardBackURL, saveObj.Driverinfo?.DriverLicenseOnURL, saveObj.Driverinfo?.DriverLicenseBackURL)

        this.printWaybillObj = {
          CarUrls: CarUrls,
          DriverUrls: DriverUrls,
          WaybillInfo: saveObj.Waybillinfo,
          WaybillId: WaybillID,
          SupplierName
        };
      }
    },

    // 打开预览pdf
    openPdmModel(item) {
      this.loading = true;
      let {
        DriverName = "", CarNumber = "", WaybillID = "", BankReceipt = '',
        ContractUrl = '', EvidenceUrl = '', CollectionAgreementUrl = ''
      } = item || {};
      this.pdfModelTitle = DriverName + "_" + CarNumber + "_" + WaybillID;

      Promise.all([
        this.openMap(item, false),
        this.getPrintWaybillObj(item, WaybillID, this.parentRoleId == 1 ? item.SupplerName : _getEnterpriseName()),
      ]).finally(() => {
        setTimeout(() => {
          this.loading = false;
          localStorage.setItem("printWaybillObj", JSON.stringify({
            ...this.printWaybillObj,
            TransportUrls: EvidenceUrl.split(','),
            FundUrls: BankReceipt.split(','),
            ContractUrls: ContractUrl.split(','),
            OtherUrls: CollectionAgreementUrl.split(',')
          }))
          localStorage.setItem("pdfModelTitle", this.pdfModelTitle)
          localStorage.setItem("mapList", JSON.stringify(this.mapList))
          const href = this.$router.resolve({
            path: `/ydpdfModel`,
          }).href;
          window.open(href, "_blank");
        }, 1000)
      });
    },
  },
  created() {
    if (this.$route.query.pagination) {
      let { pagination, searchForm } = this.$route.query
      if (searchForm) this.searchForm = searchForm
      if (pagination) this.pagination = pagination
    }

    this.parentRoleId = _getParentRoleId()

    //获取全部运单列表
    this.getAllBillList();
  },
  components: {
    DriverInfo,
    VehicleInfo,
    TXmap,
    enterpriseDetail
  },
};
</script>
<style lang="scss">
.bigWaybillManage {
  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination {
    margin-top: 10px;
  }
}

.my-label {
  font-weight: bold !important;
}

.my-label-action {
  width: 150px;
}

.evidence-dialog {
  .el-dialog {
    margin-top: 8vh !important;

    .el-dialog__body {
      padding: 10px !important;
    }
  }
}
</style>
